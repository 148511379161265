import PropTypes from "prop-types"
import React from "react"
import {Helmet} from "react-helmet"
import { Link } from "gatsby"
import { Container, Row, Col, Navbar, NavbarBrand } from 'reactstrap';
import thankou_right from "../../images/thankou_right.svg" 
import logo from "../../images/logo.svg" 
// import InputMask from 'react-input-mask'
// import axios from 'axios'
// import 'bootstrap/dist/css/bootstrap.css';
 
import "../global.scss"
import "./style.scss"

class ThankYou extends React.Component{ 
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value,},()=>{
      if (this.state.validationFunctionCall){
        this.validate()
      }
    })
  }
  
  render(){
    return(
      <div>  
        <Helmet>
          <title>Thank You</title>
          <meta name="description" content="A standout web presence, a simple CRM, easy to use marketing tools, and a powerful listing management platform with IDX and marketplace syndication all built in and all in one place." />
        </Helmet>
        <section className="thankyou__wrapper">
          <header>
            <Container>
              <Row>
                <Col lg="12" md="12" sm="12">
                  <Navbar  light expand="md">
                    <NavbarBrand href="/"><img alt="logo" className="logo_image" src={logo}/></NavbarBrand>
                    {/*<a href="tel:212.678.0100" className="ml-auto contact__number">Call: 212.678.0100</a>*/}
                  </Navbar>
                </Col>
              </Row>    
            </Container>
          </header>
          <Container>
            <Row>
              <Col>
                <div className="thankyou__container">
                  <div className="thankyou__inner">
                    <img alt="" src={thankou_right}/>
                    <h1 className="title">You’re on the list!</h1>
                    <p>Thanks for your interest in Yardpost. We’ll let you know as soon as we launch! </p>
                    <Link className="go_to-home" to={'/'}>Go Home</Link>
                  </div>
                </div>
              </Col>
            </Row>            
          </Container>
        </section>
      </div>
    )
  }
}

ThankYou.propTypes = {
  siteTitle: PropTypes.string,
}

ThankYou.defaultProps = {
  siteTitle: ``,
}

export default ThankYou