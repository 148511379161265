import React from "react"
import Layout from "../components/layout"
import ThankYoupage from "../components/ThankYou"

const ThankYou = () => (
  <Layout>
    <ThankYoupage/>
  </Layout>
)

export default ThankYou
